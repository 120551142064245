import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "./terms-conditions.scss"
import stringToHTML from "../../utils/stringToHTML.js"

const TermsConditions = ({ intl, location }) => (
  <Layout prefooter="2" menuSelector={location?.state?.menuSelector || 1}>
    <SEO title="TermsConditios" />
    <div className="terms-conditions">
      <div className="terms-conditions-header">
        <h1 className="terms-conditions-title">
          {stringToHTML(intl.formatMessage({ id: "terms-conditions-title" }))}
        </h1>
      </div>
      <div className="terms-conditions-content">
        <div
          dangerouslySetInnerHTML={{
            __html: stringToHTML(
              intl.formatMessage({ id: "terms-conditions-content-1" })
            ),
          }}
        ></div>
        <div
          dangerouslySetInnerHTML={{
            __html: stringToHTML(
              intl.formatMessage({ id: "terms-conditions-content-2" })
            ),
          }}
        ></div>
        <div
          dangerouslySetInnerHTML={{
            __html: stringToHTML(
              intl.formatMessage({ id: "terms-conditions-content-3" })
            ),
          }}
        ></div>
      </div>
    </div>
  </Layout>
)

export default injectIntl(TermsConditions)
